import { Redirect, Route } from 'react-router-dom';

import Page from '../../shared/components/Page/Page';
import RouterSwitch from '../../shared/components/RouterSwitch/RouterSwitch';
import Title from '../../shared/components/Title/Title';
import useStrategyStore from '../../shared/data/strategies';
import BusinessRuleInsights from './BusinessRuleInsights';
import ProductDetails from './ProductDetails';
import StrategyComparison from './StrategyComparison';
import StrategyDetails from './StrategyDetails';

const RecommendationsView = () => {
	const { activeStrategy } = useStrategyStore();

	// Placeholder for when users navigate to this page with an url but dont have any strategies
	if (activeStrategy === null) {
		return (
			<Page>
				<div className="flex flex-col gap-2">
					<Title>Recommendations</Title>
				</div>
				<div className="flex items-center justify-center h-72">
					<span className="text-ca-gray">Please create a strategy first</span>
				</div>
			</Page>
		);
	}

	return (
		<Page>
			<RouterSwitch>
				<Route exact path="/recommendations">
					<Redirect to="/recommendations/strategy-comparison" />
				</Route>
				<Route exact path="/recommendations/strategy-comparison">
					<StrategyComparison />
				</Route>
				<Route
					exact
					path="/recommendations/strategy-details"
					component={(props: any) => {
						return StrategyDetails({
							selectStrategy: props?.location?.state?.strategyId,
						});
					}}
				/>
				<Route exact path="/recommendations/business-rule-insights">
					<BusinessRuleInsights />
				</Route>
				<Route exact path="/recommendations/product-details">
					<ProductDetails />
				</Route>
			</RouterSwitch>
		</Page>
	);
};

export default RecommendationsView;
