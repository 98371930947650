import { GET_CUMULIO_BUSINESS_RULE_INSIGHTS_SSO } from '../../shared/api/reports';

import FullCumulioDashboard from '../../shared/components/FullCumulioDashboard/FullCumulioDashboard';
import useStrategyStore from '../../shared/data/strategies';
import filterMapToSearchParams from '../../shared/utils/filters';
import RecommendationsHeader from '../components/RecommendationsHeader';

const BusinessRuleInsights = () => {
	const { filters, activeStrategy } = useStrategyStore();
	return (
		<>
			<RecommendationsHeader />
			<FullCumulioDashboard
				// @ts-ignore
				queryKey={[
					activeStrategy,
					'business-rule-insights',
					Object.fromEntries(filters),
				]}
				ssoQuery={() =>
					GET_CUMULIO_BUSINESS_RULE_INSIGHTS_SSO(
						activeStrategy,
						filterMapToSearchParams(filters)
					)
				}
				showLoading
				hardReload
			/>
		</>
	);
};

export default BusinessRuleInsights;
